<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>

          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card>
            <v-card-title>Sign up</v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="signupForm.valid"
                @submit.prevent="handleSubmit"
                lazy-validation
              >
                <v-text-field
                  v-model="signupForm.email"
                  :rules="rules.email"
                  label="Email"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="signupForm.realname"
                  :rules="[(v) => !!v || '']"
                  label="Real Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="signupForm.password"
                  :append-icon="
                    signupForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v.length >= 8) || '8 chars at least',
                  ]"
                  :type="signupForm.showPassword ? 'text' : 'password'"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="
                    signupForm.showPassword = !signupForm.showPassword
                  "
                ></v-text-field>

                <v-text-field
                  v-model="signupForm.repeatPassword"
                  :append-icon="
                    signupForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :rules="[
                    (v) => !!v || '',
                    (v) =>
                      (v && v == signupForm.password) || 'Password mismatch',
                  ]"
                  :type="signupForm.showPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  hint="repeat password"
                  counter
                  @click:append="
                    signupForm.showPassword = !signupForm.showPassword
                  "
                ></v-text-field>

                <v-card-actions class="justify-center">
                  <v-progress-circular
                    v-if="waiting"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn
                    type="submit"
                    v-else
                    :disabled="
                      !signupForm.valid ||
                      !signupForm.realname ||
                      !signupForm.email ||
                      !signupForm.password
                    "
                    color="primary"
                    class="mr-4"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import consts from "@/consts";

const axios = require("axios");
const requests = axios.create({
  timeout: 20000,
});

export default {
  data: () => ({
    rules: consts.INPUT_RULES,
    alerts: null,
    messages: null,
    waiting: false,
    signupForm: {
      valid: false,
      email: "",
      realname: "",
      password: "",
      repeatPassword: "",
      showPassword: false,
    },
  }),
  methods: {
    handleSubmit() {
      this.waiting = true;
      requests
        .post(`/api/auth/signup`, {
          realname: this.signupForm.realname,
          email: this.signupForm.email,
          password: this.signupForm.password,
        })
        .then((response) => {
          this.waiting = false;
          this.alerts = null;
          let data = response.data;
          alert(data.message);
          this.$router.push("/login");
        })
        .catch((err) => {
          this.waiting = false;
          this.messages = null;
          if (err.response == null) {
            alert("unknown server error");
            return;
          }
          const data = err.response.data;
          if (err.response.status >= 500) {
            this.alerts = "server error";
          } else if (err.response.status >= 400) {
            if (data != null) {
              this.alerts = data.detail;
            } else {
              this.alerts = "bad request";
            }
          } else {
            this.alerts = "unknown error";
          }
        });
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[(_vm.alerts)?_c('v-alert',{attrs:{"color":"red lighten-1","type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.alerts)+" ")]):_vm._e(),(_vm.messages)?_c('v-alert',{attrs:{"color":"green lighten-1","type":"success","dense":""}},[_vm._v(" "+_vm._s(_vm.messages)+" ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-card',[_c('v-card-title',[_vm._v("Sign up")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.signupForm.valid),callback:function ($$v) {_vm.$set(_vm.signupForm, "valid", $$v)},expression:"signupForm.valid"}},[_c('v-text-field',{attrs:{"rules":_vm.rules.email,"label":"Email","required":""},model:{value:(_vm.signupForm.email),callback:function ($$v) {_vm.$set(_vm.signupForm, "email", $$v)},expression:"signupForm.email"}}),_c('v-text-field',{attrs:{"rules":[(v) => !!v || ''],"label":"Real Name","required":""},model:{value:(_vm.signupForm.realname),callback:function ($$v) {_vm.$set(_vm.signupForm, "realname", $$v)},expression:"signupForm.realname"}}),_c('v-text-field',{attrs:{"append-icon":_vm.signupForm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  (v) => !!v || '',
                  (v) => (v && v.length >= 8) || '8 chars at least',
                ],"type":_vm.signupForm.showPassword ? 'text' : 'password',"label":"Password","hint":"At least 8 characters","counter":""},on:{"click:append":function($event){_vm.signupForm.showPassword = !_vm.signupForm.showPassword}},model:{value:(_vm.signupForm.password),callback:function ($$v) {_vm.$set(_vm.signupForm, "password", $$v)},expression:"signupForm.password"}}),_c('v-text-field',{attrs:{"append-icon":_vm.signupForm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  (v) => !!v || '',
                  (v) =>
                    (v && v == _vm.signupForm.password) || 'Password mismatch',
                ],"type":_vm.signupForm.showPassword ? 'text' : 'password',"label":"Confirm Password","hint":"repeat password","counter":""},on:{"click:append":function($event){_vm.signupForm.showPassword = !_vm.signupForm.showPassword}},model:{value:(_vm.signupForm.repeatPassword),callback:function ($$v) {_vm.$set(_vm.signupForm, "repeatPassword", $$v)},expression:"signupForm.repeatPassword"}}),_c('v-card-actions',{staticClass:"justify-center"},[(_vm.waiting)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":!_vm.signupForm.valid ||
                    !_vm.signupForm.realname ||
                    !_vm.signupForm.email ||
                    !_vm.signupForm.password,"color":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }